<template>
	<div class="card">
    <div class="card-body">
      <div class="row"><div class="col"><h4><span class="bg-secondary"># {{$t('report.client_debt')}}</span></h4></div></div>
      <div class="row">
        <div class="col-6" v-if="is_admin && cocon && this.ls_branch_ddl.length>0">
          <label for="validationBranch">{{$t('common.select_branch')}}</label>
          <multiselect v-model="search_form.ls_branch" :tag-placeholder="$t('common.select_branch')"
            :placeholder="$t('common.placeholder_branch')" label="name" :select-label="$t('common.enter_to_select')"
            track-by="id" :options="ls_branch_ddl" :multiple="true"
            :taggable="true" :deselect-label="$t('common.enter_to_remove')"></multiselect>
        </div>

        <div class="col-2">
          <label for="validationNgayFromTo">{{$t('common.from_date')}}</label>
          <date-picker v-model="search_form.tungay" :config="options" class="date-picker-height"></date-picker>
          <div v-if="$v.$error">
            <p class="text-danger" v-if="!$v.search_form.tungay.required">{{$t('common.date_required')}}</p>
          </div>
        </div>
        <div class="col-2">
          <label for="validationNgayFromTo">{{$t('common.to_date')}}</label>
          <date-picker v-model="search_form.denngay" :config="options"></date-picker>
          <div v-if="$v.$error">
            <p class="text-danger" v-if="!$v.search_form.denngay.required">{{$t('common.date_required')}}</p>
          </div>
        </div>
        <div class="col-2 form-inline">
          <a href="#" @click="exportBaoCaoPdf()" class="btn btn-primary"
          id="validationSubmit">{{$t('common.view_report')}}</a>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col" id="pdf_client_debt"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import pdfobj from 'pdfobject'
import { required} from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay'
import datePicker from 'vue-bootstrap-datetimepicker'
import {Role} from '../../helpers/role'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default {
	components: {Loading,datePicker,Multiselect},
	data () {
		return {
			search_form:{
        company_id:null,
        branch_id:null,
        company_cocon:false,
				ls_branch:[],
				tungay:moment().clone().startOf('month').format('DD-MM-YYYY'),
        denngay:moment().format('DD-MM-YYYY'),
        lang:null
			},
			options: {
				format: 'DD-MM-YYYY',
				useCurrent: false,
				showClear: true,
        showClose: true
			}
		}
	},
	validations: {
    search_form: {
      tungay: {
        required
      },
      denngay: {
        required
      }
    }
  },
	computed: {
    is_admin () {
      let user= this.$store.state.st_authentication.user
      return user&& user.role==Role.Admin
    },
    is_branch () {
      let user= this.$store.state.st_authentication.user
      return user&& user.role==Role.Branch_Admin
    },
    cocon () {
      let user= this.$store.state.st_authentication.user
      return Boolean(user.company.cocon)
    },
    business () {
      let user= this.$store.state.st_authentication.user
      return user&&user.company.business
    },
    user_login () {
      return this.$store.state.st_authentication.user
    },
    ls_branch_ddl(){
      return this.$store.state.st_branch.ls_branch_ddl
    }
  },
	mounted(){
		let vm=this
		if(vm.is_admin){
			vm.get_ls_branch(vm.user_login.company_id)
    }
    vm.exportBaoCaoPdf()
	},
	methods: {
		get_ls_branch(company_id){
      this.$store.dispatch('st_branch/get_ls_branch_ddl',company_id)
		},
		exportBaoCaoPdf() {
      var app = this
			debugger
			app.$v.search_form.$touch()
			if (!app.$v.search_form.$invalid)
			{
				app.search_form.company_id=app.user_login.company_id
				app.search_form.business=app.business
				app.search_form.company_cocon=app.cocon
        app.search_form.lang=app.$i18n.locale
        debugger
				app.$store.dispatch('st_order/report_client_debt',app.search_form).then((response) => {
					if(response && response.data){
						let blob = new Blob([response.data], { type: 'application/pdf' })
						pdfobj.embed(window.URL.createObjectURL(blob), "#pdf_client_debt")
					}
				})
			}
		}
	}
}
</script>

<style scoped>
	.pdfobject-container { width: 100%; height: 80rem; border: 1rem solid rgba(0,0,0,.1); }
</style>
